@use 'breakpoints';
@use 'texts';
@use 'colors';
@use 'scrollbar';
@import './variables';
@import 'mixins';

$overlay-padding: $spacing-m;
$overlay-padding-large: $spacing-xl;
$type-s-max-width: px2rem(327);
$type-m-max-width: px2rem(464);
$type-l-max-width: px2rem(700);
$type-xl-max-width: px2rem(1116);
$entry-function: cubic-bezier(0.3, 0.85, 0.5, 1);
$exit-function: cubic-bezier(0.5, 0, 0, 1);

.bodyModalOpen {
	scrollbar-width: none; /* Firefox */

	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
}

.dialog {
	height: fit-content;
	width: auto;
	text-align: start;
	border: none;
	background-color: colors.$background-default;
	padding: 0 $spacing-s;
	max-height: calc(100dvh - ($overlay-padding * 2));
	overscroll-behavior-y: contain;
	overflow: hidden auto;
	box-sizing: border-box;

	.header {
		padding-block-start: $spacing-s;
	}

	&::backdrop {
		background-color: colors.$background-overlay;
	}

	@media (min-width: breakpoints.$medium) {
		inset: $overlay-padding-large;
		max-height: calc(100dvh - ($overlay-padding-large * 2));
	}
}

.modal {
	.modalContent {
		overflow-x: hidden;
	}
}

.footer {
	padding-block-end: $spacing-s;
	position: sticky;
	background-color: colors.$background-default;
	bottom: 0;
	width: 100%;
}

.footerContent {
	box-sizing: content-box;
}

.modalType {
	&S,
	&M,
	&L,
	&XL {
		margin: auto;
		max-height: calc(100dvh - ($overlay-padding * 2));
		inset: $overlay-padding;

		.modal {
			display: contents;
		}
	}

	&S {
		max-width: $type-s-max-width;
	}

	&M {
		max-width: $type-m-max-width;
	}

	&L {
		max-width: $type-l-max-width;
	}

	&XL {
		max-width: $type-xl-max-width;
	}

	&M,
	&L,
	&XL {
		@media (min-width: breakpoints.$medium) {
			padding: 0 $spacing-m;

			.header,
			.footer {
				padding-block-start: $spacing-m;
			}
		}
	}

	&L,
	&XL {
		@media (min-width: breakpoints.$large) {
			padding: 0 $spacing-l;

			.header,
			.footer {
				padding-block-start: $spacing-l;
			}
		}
	}

	&SIDE,
	&SIDE_FULL_CONTENT,
	&SHEET,
	&SHEET_SMALL,
	&SHEET_FULL_SCREEN,
	&SHEET_INSPIRATIONAL {
		overscroll-behavior-y: contain;

		.modal {
			display: flex;
			flex-direction: column;
		}

		.modalContent {
			flex: 1;
		}

		&.animate {
			&::backdrop {
				opacity: 1;
				transition: opacity 350ms $entry-function;
			}
		}

		&::backdrop {
			opacity: 0;
			transition: opacity 350ms $exit-function;
		}
	}

	&SIDE,
	&SIDE_FULL_CONTENT,
	&SHEET_FULL_SCREEN {
		height: 100dvh;
		overflow: hidden;

		.modal {
			height: 100%;
		}
	}

	&SIDE {
		@media (min-width: breakpoints.$large) {
			padding: 0 40px;
		}

		.header {
			padding-block: $spacing-l;
		}

		.footerContent {
			padding-block-start: $spacing-s;
		}

		.footer {
			padding-block-end: $spacing-s;
		}

		@media (min-width: breakpoints.$large) {
			.footerContent,
			.header {
				padding-block-start: 40px;
			}

			.footer {
				padding-block-end: 40px;
			}
		}
	}

	&SIDE_FULL_CONTENT {
		padding: 0;

		.modal {
			display: block;
		}

		.header,
		.footer {
			padding: 0;
		}
	}

	&SIDE,
	&SIDE_FULL_CONTENT {
		margin: 0;
		max-width: none;
		inset-block-start: 0;
		inset-inline-end: 0;
		height: 100dvh;
		width: 100%;
		max-height: none;
		animation: closing-right 350ms $exit-function;
		animation-fill-mode: forwards;

		&:dir(rtl) {
			animation: closing-left 350ms $exit-function;
			animation-fill-mode: forwards;
		}

		&.animate {
			animation: opening-left 350ms $entry-function;

			&:dir(rtl) {
				animation: opening-right 350ms $entry-function;
			}
		}

		@media (min-width: breakpoints.$medium) {
			inset-inline-start: calc(100vw - px2rem(500));
			width: px2rem(500);
		}
	}

	&SHEET,
	&SHEET_SMALL,
	&SHEET_FULL_SCREEN,
	&SHEET_INSPIRATIONAL {
		width: 100%;
		max-width: 100vw;
		inset-inline: 0;
		margin: 0;
		padding-block-start: 0;

		.header {
			padding-block: 0;
		}
	}

	&SHEET_FULL_SCREEN {
		inset-block: 0;
		max-height: 100dvh;
		height: 100dvh;
		transform: translateY(100%);

		&.animate {
			transform: translateY(0);
			transition: transform 350ms $entry-function;
		}

		.modalContent {
			height: 100%;
		}
	}

	&SHEET,
	&SHEET_SMALL,
	&SHEET_INSPIRATIONAL {
		position: fixed;
		inset: unset;
		inset-block-end: 0;
		max-height: calc(100dvh - $spacing-xxl);
		transform: translateY(100%);
		transition: transform 350ms $exit-function;
		margin: unset;
		inset-inline-start: 0;

		&.animate {
			transform: translateY(0);
			transition: transform 350ms $entry-function;
		}
	}

	&FULL_SCREEN {
		max-width: unset;
		max-height: unset;
		height: 100dvh;
		inset: 0;
		margin: 0;
		padding: 0;
		padding-block-start: 0;
		transform: scale(0.93);
		opacity: 0;
		transform-origin: center;
		transition:
			transform 500ms $exit-function,
			opacity 500ms $exit-function;

		&::backdrop {
			opacity: 0;
			transition: opacity 500ms $exit-function;
		}

		.header,
		.footer {
			padding-block: unset;
		}

		.modal,
		.modalContent {
			height: 100%;
		}

		&.animate {
			transform: scale(1);
			opacity: 1;
			transition:
				transform 500ms $entry-function,
				opacity 500ms $entry-function;

			&::backdrop {
				opacity: 1;
				transition: opacity 500ms $entry-function;
			}
		}
	}
}

@keyframes opening-left {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes opening-right {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes closing-right {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(100%);
	}
}

@keyframes closing-left {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}
