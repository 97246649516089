@use 'colors';
@use 'breakpoints';
@use 'outline';
@import '../variables';

.close {
	cursor: pointer;
	border: unset;
	padding: 0;
	height: 1.5rem;
	font-size: 1.5rem;
	position: absolute;
	inset-inline-end: 0;
	background-color: transparent;
	inset-block-start: 0;

	&:focus-visible {
		outline: none;
	}

	:global(.k) &:focus-visible {
		@extend %default-outline-with-offset;
	}
}

.bar {
	border-radius: 0.125rem;
	background-color: colors.$border-strong;
	width: 3.5rem;
	height: 0.125rem;
	margin-inline: auto;
	cursor: pointer;
	border: unset;
	margin-block: $spacing-xs (-$spacing-xs);
}

.float {
	background-color: colors.$background-default;
	display: flex;
	align-items: center;
	cursor: pointer;
	border: unset;
	padding: 0;
	font-size: 1rem;
	width: 34px;
	height: 34px;
	inset-inline-end: 0;
	justify-content: center;
	z-index: 1; // above modal content
	margin: $spacing-s;

	@media (min-width: breakpoints.$large) {
		height: 24px;
		width: 24px;
	}
}

.margin {
	margin-block-start: $spacing-s;
}
