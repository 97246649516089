@use 'texts';
@use 'colors';

.small {
	@extend %text-body-s-regular;
}

.anchor {
	@extend %text-body-m-regular;
}

.small,
.anchor {
	color: colors.$content-default;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.bold {
	@extend %text-body-m-regular;
}

.italic {
	@extend %text-body-m;

	font-style: italic;
}

.paragraph {
	@extend %text-body-m;
}

.button {
	@extend %text-body-m-regular-underline;

	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	cursor: pointer;
}

.underline {
	@extend %text-body-m;

	text-decoration: underline;
}
