@use 'colors';
@import 'mixins';

.srOnlyFocus,
.srOnly {
	clip: rect(0, 0, 0, 0);
	position: absolute;
	margin: -1px;
	border-width: 0;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	white-space: nowrap;
}

.srOnlyFocus {
	&:active,
	&:focus {
		clip: initial;
		position: static;
		margin: inherit;
		padding: inherit;
		width: auto;
		height: auto;
		overflow: initial;
	}
}

.skeleton {
	position: relative;
	font-size: 0;

	&::after {
		display: block;
		position: absolute;
		inset: 0;
		animation: skeleton-animation 3s ease infinite;
		box-sizing: border-box;
		border-radius: px2rem(2);
		background: linear-gradient(90deg, #{colors.$background-subtle}, #{colors.$background-overlay});
		background-size: 400% 400%;
		content: '';
		font-size: 0;
	}
}

@keyframes skeleton-animation {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}
