/* stylelint-disable selector-class-pattern */
@use 'colors';

.icon-content-1 {
	fill: colors.$content-default;
}

.icon-content-2 {
	fill: colors.$content-subtle;
}

.icon-content-3 {
	fill: colors.$content-strong;
}

.icon-content-disabled {
	fill: colors.$content-disabled;
}

.icon-content-inverse {
	fill: colors.$content-strong-on-inverse;
}

.icon-content-sales {
	fill: colors.$promotions-promotion-sales;
}

.icon-semantic-danger {
	fill: colors.$semantic-error;
}

.icon-semantic-warning {
	fill: colors.$semantic-warning;
}

.icon-semantic-success {
	fill: colors.$content-sustainability;
}

:global([dir='rtl']) {
	.rotate-rtl {
		transform: scaleX(-1);
	}
}
