@use 'texts';
@use 'colors';
@import '../variables';

.title {
	@extend %text-title-xl;

	margin: 0;
	padding: 0;
	word-break: break-all;

	&.sheetTitle {
		@extend %text-title-m;
	}
}

.subtitle {
	@extend %text-body-s;

	margin: 0;
	padding: 0;
	padding-block-end: 12px;
	word-break: break-all;
}

.header {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: content-box;
	gap: 0.5rem;
	width: 100%;
	min-height: $head-m;
	position: sticky;
	inset-block-start: 0;
	background-color: colors.$background-default;

	> .title {
		position: relative;
	}

	&.noHeight {
		min-height: unset;
		inset-block-start: unset;
		position: relative;
	}

	&.closeOnly {
		min-height: $head-s;
	}

	&.withTitle {
		min-height: $head-s;
	}

	&.withSeparator {
		min-height: $head-l;
		margin-block-end: $spacing-s;
		border-bottom: 1px solid colors.$border-subtle;
		margin-inline: -$spacing-s;
		padding-inline: $spacing-s;
	}
}

.titles {
	flex: 1;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;

	&.centered {
		margin-block-start: $spacing-s;
		text-align: center;
	}

	&.withCloseButton {
		margin-inline-end: 24px;
	}
}

.positionAnchor {
	position: relative;
	flex: 1;
}
