@use 'colors';
@use 'outline';
@use 'texts';
@use '../styles/base';
@import '../styles/states';
@import 'mixins';

.disabled,
.inverse,
.default {
	@extend %button;

	background: transparent;
	height: initial;
	min-width: initial;
	padding: 0;
	gap: px2rem(4);
}

.default {
	@include states(
		'color',
		colors.$content-accent,
		colors.$content-accent-hover,
		colors.$content-accent-active
	);
}

.inverse {
	@include states(
		'color',
		colors.$content-accent-on-inverse,
		colors.$content-accent-on-inverse-hover,
		colors.$content-accent-on-inverse-active
	);

	&:focus-visible {
		@extend %inverse-outline-with-offset;
	}
}

[aria-disabled='true'] {
	&.default,
	&.inverse {
		color: colors.$content-disabled;
	}
}

.small {
	@extend %text-body-s-regular-underline;

	svg {
		width: px2rem(16);
		height: px2rem(16);
	}
}

.medium {
	@extend %text-body-m-regular-underline;
}
